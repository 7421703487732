import styled from 'styled-components'
import { theme, ifProp, withProp } from 'styled-tools'
import { rem, tint } from 'polished'

const Separator = styled.div`
  width: ${rem(120)};
  height: ${rem(1)};
  margin: 0 auto;
  background: ${ifProp('inverted', theme('colors.white'), withProp('theme.colors.primary', tint(0.8)))};
`

export default Separator
