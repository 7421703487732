import React from 'react'
import styled from 'styled-components'
import { theme, switchProp } from 'styled-tools'
import { rem, transparentize } from 'polished'
import Img from 'gatsby-image'
import { media } from '../../theme'

import Separator from './Separator'

const Wrapper = styled.section`
  position: relative;
`
const MiddleWrapper = styled.div`
  background: ${switchProp(
    'type',
    {
      primary: theme('colors.primary'),
      light: theme('colors.light'),
      dark: theme('colors.dark'),
    },
    theme('colors.white'),
  )};
  color: ${switchProp(
    'type',
    {
      primary: theme('colors.white'),
      dark: theme('colors.white'),
    },
    theme('colors.primaryText'),
  )};

  h1,
  h2 {
    color: ${theme('colors.black')};
    line-height: 1.2;
  }

  h3 {
    color: ${switchProp(
      'type',
      {
        primary: theme('colors.white'),
        dark: theme('colors.white'),
      },
      theme('colors.lightPrimary'),
    )};
  }
  padding: ${rem(28)} ${rem(21)};
  ${media('sm')`
    padding: ${rem(56)} ${rem(28)};
  `}
`
const InnerWrapper = styled.div`
  max-width: ${rem(800)};
  margin: 0 auto;
`

const Glass = styled(MiddleWrapper)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme, inverted }) => transparentize(0.1, inverted ? theme.colors.primary : theme.colors.white)};
`

const StyledImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Block = ({ className, children, image, type }) => {
  const Container = image ? Glass : MiddleWrapper
  return type === 'separator' ? (
    <Separator />
  ) : (
    <Wrapper>
      {image && <StyledImage {...image} />}
      <Container type={type}>
        <InnerWrapper className={className}>{children}</InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Block
